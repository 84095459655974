import _ from 'lodash'
import React from 'react'
import classNames from 'classnames'
import apis from 'browser/app/models/apis'
import { Classes } from '@blueprintjs/core'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Query } from 'shared-libs/models/query'
import { SchemaUris } from 'shared-libs/models/schema'
import { Collection } from 'shared-libs/models/collection'
import { browserHistory } from 'browser/history'
import queryString from 'query-string'

import { Entity } from 'shared-libs/models/entity'
import { getRelativeLink, isVectorLink } from 'browser/app/utils/utils'
import { WithMobileHeader } from '../contexts/mobile-header-context'
import { ErrorModal } from 'browser/components/atomic-elements/organisms/error-modal'

import './_mobile-kiosk-search.scss'
import { PlatformType } from 'shared-libs/models/types/storyboard/storyboard-plan'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import {
  getStoryboardExecution,
  materializeProps,
} from 'shared-libs/models/types/storyboard/storyboard-utils'
import { EsprimaParser, ExpressionEvaluator, ValueGetter } from 'shared-libs/helpers/evaluation'
import { CustomFormulas } from 'shared-libs/helpers/formulas'
import { ImageLoader } from 'browser/components/atomic-elements/atoms/image-loader/image-loader'

const scanIcons = require('images/kiosk/scanicons.svg')
interface ShipmentSearchState {
  searchValue: string
  isLoading: boolean
}

/**
 *  A kiosk landing page with a input field to search for existing execution and jump into it
 */
export class KioskSearchPage extends React.Component<{}, ShipmentSearchState> {
  private collection: any
  private searchRef: React.RefObject<any>
  private evaluator: ExpressionEvaluator

  constructor(props: {}) {
    super(props)
    this.state = {
      searchValue: '',
      isLoading: true,
    }
    this.searchRef = React.createRef()
    this.evaluator = this.createQueryEvaluator()
  }

  public componentDidMount() {
    document.addEventListener('keydown', this.handleDocumentKeyDown)

    // loading this page will enable koisk mode
    apis?.getSettings().setPlatform(PlatformType.KIOSK)

    // resolve any schema dependencies
    const schemaDepencies = this.getSchemaDependencies()
    this.resolveDependencies(schemaDepencies)
      .then((entities) => {
        console.log('Dependencies resolved', entities)
        this.setState({ isLoading: false })
      })
      .catch((err) => console.error('Unale to resolve schemas', schemaDepencies, err))
  }

  public componentWillUnmount(): void {
    document.removeEventListener('keydown', this.handleDocumentKeyDown)
  }

  public render() {
    const { isLoading } = this.state

    if (isLoading) {
      return <LoadingSpinner />
    }

    return (
      <WithMobileHeader title={''} languageButton={{ handler: _.noop }}>
        <div className="mobile-kiosk-search-container">
          <div className="mobile-kiosk-search-company-logo-container">
            <ImageLoader className="mobile-kiosk-search-company-logo" src={this.getLogoUri()} />
          </div>
          <div className="mobile-kiosk-search-instruction-container">
            <p className="mobile-kiosk-search-instruction">
              {this.getKioskSettingsValue(
                'searchInstruction',
                'Scan QR / barcode or enter shipment number below to find and print your paperwork.'
              )}
            </p>
            <div className="mobile-kiosk-search-icons-container">
              <img src={scanIcons} alt="Scanner Icon" className="mobile-kiosk-search-icons"></img>
            </div>
            <div className="mobile-kiosk-search-or-divider-container">
              <div className="mobile-kiosk-search-or-divider">or</div>
            </div>
            <input
              type="text"
              placeholder={this.getKioskSettingsValue('searchPlaceholder', 'Enter shipment number')}
              ref={this.searchRef}
              value={this.state.searchValue}
              onChange={this.handleInputChange}
              onKeyDown={this.handleEnterKeyDown}
              autoFocus={true}
              className="mobile-kiosk-search-input-field"
            />
            <Button
              className={classNames(
                'mb4',
                Classes.INTENT_PRIMARY,
                Classes.FILL,
                'mobile-kiosk-search-button'
              )}
              data-debug-id="kiosk-search-button"
              tabIndex={1}
              onClick={this.handleOnClick}
            >
              {this.getKioskSettingsValue('searchButtonLabel', 'SEARCH FOR SHIPMENT')}
            </Button>
          </div>
        </div>
      </WithMobileHeader>
    )
  }

  private handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchValue: event.target.value })
  }

  /**
   * Auto search on "Enter" keydown to handle the return carriage from the barcode scanner.
   * The barcode by default append the enter carriage and we use this to perform the search right away
   */
  private handleEnterKeyDown = (e) => {
    if (e.key === 'Enter' && !_.isEmpty(this.state.searchValue)) {
      // Navigate to the next page, or handle as needed
      this.handleOnClick()
    }
  }

  /**
   * Global keydown handler to redirect key input from outside the target search box
   * and focus it into the search box for handling.
   */
  private handleDocumentKeyDown = (e) => {
    if (document.activeElement !== this.searchRef.current) {
      e.preventDefault()

      if (e.key === 'Enter' && !_.isEmpty(this.state.searchValue)) {
        this.handleOnClick()
        return
      }

      this.searchRef?.current?.focus()
      this.setState({ searchValue: this.state.searchValue + e.key })
    }
  }

  private handleOnClick = async () => {
    const { searchValue } = this.state

    await this.isDeeplink(searchValue)
      ? this.handleDeepLink(searchValue)
      : this.handleSearch()
  }

  private handleSearch = () => {
    // Implement search functionality here
    console.log('Searching for shipment:', this.state.searchValue)
    return this.queryShipment().then((entities) => {
      return this.processShipments(entities)
    })
  }

  private isDeeplink = (value: string) => {
    return isVectorLink(value)
  }

  private handleDeepLink = (value: string) => {
    const relativeLink = getRelativeLink(value)
    browserHistory.push(relativeLink)
  }

  /**
   * Handle search result
   * No match: alert popup
   * One match: jump directly to the workflow kiosk story
   * Multiple match: TODO - display a selection screen???
   *
   */
  private processShipments = async (entities: Entity[]) => {
    if (_.isEmpty(entities)) {
      return this.handleNoMatch()
    } else if (_.size(entities) > 1) {
      return this.handleMultiMatches(entities)
    }

    return this.handleExactMatch(_.first(entities))
  }

  private handleNoMatch = async () => {
    ErrorModal.open({
      errorTitle: 'No shipment found',
      errorText: 'Please check the shipment number and try again',
    })
  }

  /**
   * TODO: placeholder until the next release to handle multiple matches
   */
  private handleMultiMatches = async (entities: Entity[]) => {
    ErrorModal.open({
      errorTitle: 'Multiple matches found',
      errorText: 'Please check the shipment number and try again',
    })
  }

  /**
   *  Jump directly to workflow with kiosk story
   * @param entity
   */
  private handleExactMatch = async (entity: Entity) => {
    this.navigateToKioskStory(entity)
  }

  private navigateToKioskStory = (entity: Entity) => {
    const executionEntity = getStoryboardExecution(entity)
    const kioskStory = _.first(executionEntity.getStoriesForCurrentUser(PlatformType.KIOSK))

    if (_.isEmpty(kioskStory)) {
      const message = this.getKioskSettingsValue(
        'noKioskStory',
        'This plan does not have kiosk story'
      )

      ErrorModal.open({
        errorTitle: 'No kiosk story',
        errorText: message,
      })
      return
    }

    const searchParams = {
      story_id: kioskStory?.id,
    }

    browserHistory.push({
      pathname: `/entity/${entity.uniqueId}`,
      search: queryString.stringify(searchParams),
    })
  }

  private queryShipment = async (): Promise<Entity[]> => {
    const searchQuery = this.getSearchQuery()

    const query = new Query(apis)
      .setEntityType(searchQuery?.entityType)
      .setOrders(searchQuery?.orders)
      .setFilters(searchQuery?.filters)

    this.collection?.dispose()
    this.collection = new Collection(apis, query)

    return await this.collection.find()
  }

  /**
   * Resolve any kiosk dependencies that are outside the firm bundle
   */
  private resolveDependencies = async (schemaUris: string[]): Promise<Entity[]> => {
    return await apis.getStore()?.resolveEntitiesByUri(schemaUris)
  }

  private getSchemaDependencies = (): string[] => {
    return [this.getSearchQueryType()]
  }

  /********************************************************************************/
  /**
   * Helper functions to extract from kiosk settings
   */

  private getKioskSettingsValue = (name, defaultValue) => {
    const kioskSettings = apis.getSettings().getKioskSettings()
    return _.get(kioskSettings, 'core_settings_kiosk.' + name, defaultValue)
  }

  private getLogoUri = (): string => {
    const firm = apis.getSettings().getFirm()
    const kioskSettings = apis.getSettings().getKioskSettings()

    const firmLogo = _.get(firm, 'business.logo.uri')

    return _.get(kioskSettings, 'core_settings_kiosk.logo.uri') ?? firmLogo
  }

  private getSearchQueryType = (): string => {
    const kioskSettings = apis.getSettings().getKioskSettings()
    return _.get(
      kioskSettings,
      'core_settings_kiosk.searchQuery.entityType',
      SchemaUris.STORYBOARD_YMS_EXECUTION
    )
  }

  private getSearchQuery = (): any => {
    const defaultQuery = {
      entityType: SchemaUris.STORYBOARD_YMS_EXECUTION,
      orders: [
        {
          path: 'modifiedDate',
          type: 'descending',
        },
      ],
      filters: [
        {
          type: 'match',
          path: 'core_storyboard_execution.status',
          values: ['Active', 'Pending', 'Completed'],
        },
        {
          type: 'match',
          path: 'core_storyboard_execution.name',
          value: {
            type: 'expression',
            value: 'searchValue',
          },
        },
      ],
    }

    const query = this.getKioskSettingsValue('searchQuery', defaultQuery)

    return materializeProps(query, this.evaluator)
  }

  /**
   * To evaluate query from kiosk settings.  The kiosk settings query is able to get access to the following context
   *
   * settings: user settings
   * searchValue: current input search value
   * all formulas
   *
   */
  private createQueryEvaluator = () => {
    const context = {
      settings: apis.getSettings(),
      ...CustomFormulas,
    }

    const valueGetter: ValueGetter = (key) => {
      return _.get(this.state, key, _.get(context, key))
    }

    return ExpressionEvaluator.create().setASTParser(EsprimaParser).setValueGetter(valueGetter)
  }
}
